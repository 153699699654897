<template>
    <div class="d-flex align-items-center mb-2 mt-2 mx-3 flex-wrap flex-md-nowrap">
        <div class="d-block d-md-flex w-100">
            <CDropdown :key="`${selectedTasks.length}-${type}`" color="primary" class="mr-2 mb-2 w-md-auto w-100 d-flex"
                       style="min-width: 200px">
                <CDropdownToggle color="primary" variant="outline" :key="`${selectedTasks.length}`"
                                 :disabled="!selectedTasks.length"
                                 class="text-start d-flex align-items-center justify-content-between">
                    Bulk Action
                </CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem @click="download">Download</CDropdownItem>
                </CDropdownMenu>
            </CDropdown>

            <CInputGroup class="mr-2 mb-2 w-md-auto w-100">
                <CInputGroupText>
                    <CIcon name="cil-search"/>
                </CInputGroupText>
                <CFormInput v-model="search" placeholder="Search"/>
            </CInputGroup>
        </div>
    </div>
    <div class="px-2">
        <SmartTable :items="tasks" :items-per-page="parseInt(perPage)" :columns="fields" :sorter-value="sort"
                    @sorter-change="onSort" selectable="id" :selected-items="selectedTasks"
                    @selected-items-change="s => selectedTasks = s" :loading="loading" :active-page="activePage"
                    @update-active-page="(value) => (activePage = value)" :per-page="perPage"
                    @update-per-page="(value) => (perPage = value)"
                    :pages="pages" :clear-text="search.length ? 'No search results found' : 'No Data yet'">
            <template #status="{ item, column }">
                <TaskStatusBadge v-if="column.key === 'file_name'" :task="item"/>
            </template>
            <template #project_name="{ item, column }">
                <router-link :to="{name: 'ProjectDashboard', params: {id: item.project.id}}">
                    {{ item.project.name }}
                </router-link>
            </template>
            <template #project_number="{ item, column }">
                {{ item.project.internal_number }}
            </template>
            <template #internal_assignee_name="{ item, column }">
                {{ item.internal_assignee.full_name }}
            </template>
            <template #external_assignee_name="{ item, column }">
                {{ item.external_assignee.full_name }}
            </template>
            <template #quick_access="{item}">
                <td>
                    <Star @click="changeFav(item)" :active="item.is_favorite"/>
                </td>
            </template>
        </SmartTable>
    </div>
</template>

<script>
import table from '@/mixin/table';
import Star from '@/components/Star';
import onTimeoutSearch from "@/mixin/searchTimeout";
import dateFormater from "@/mixin/dateFormater";
import {DONE_TASKS, PENDING_APPROVAL} from "@/domain/Entities/Task/taskStatuses";
import Tasks from "@/api/v2/endpoints/Tasks";
import TaskStatusBadge from "@/components/Task/TaskStatusBadge.vue";
import {CONTRACT_TEMPLATE_TYPE} from "@/domain/Entities/Template/templateTypes";

const PENDING_TYPE = 'pending';
const COMPLETED_TYPE = 'completed';
const FAVORITE_TYPE = 'favorite';

export default {
    components: {TaskStatusBadge, Star},
    mixins: [table, onTimeoutSearch, dateFormater],
    inject: ['downloadToast'],
    props: {
        type: {
            type: String,
            validator: (value) => [PENDING_TYPE, COMPLETED_TYPE, FAVORITE_TYPE].includes(value),
        },
    },
    data: (v) => ({
        loading: false,
        externalCompanyId: null,
        selectedTasks: [],
        tasks: [],
        fields: [
            {
                key: 'quick_access',
                _style: 'max-width: 30px; min-width: auto',
                sorter: false
            },
            {
                key: 'file_name',
                label: 'File Name',
                click: (item) => v.goToView(item)
            },
            {
                key: 'project_name',
                label: 'Project Name',
            },
            {
                key: 'project_number',
                label: 'Project Number'
            },
            {
                key: 'name',
                label: 'Task Name',
                click: (item) => v.goToView(item)
            },
            {
                key: 'due_date',
                label: 'Due Date',
                format: (value) => {
                    return v.formatDate(value)
                },
            },
            {
                key: 'internal_assignee_name',
                label: 'Internal Owner',
            },
            {
                key: 'external_assignee_name',
                label: 'Assigned To',
            },
        ],
    }),
    computed: {
        isFavoriteType() {
            return this.type === FAVORITE_TYPE;
        },
        taskStatuses() {
            if (this.isFavoriteType) return [];

            return this.type === COMPLETED_TYPE ? DONE_TASKS : [PENDING_APPROVAL];
        }
    },
    watch: {
        search() {
            this.onTimeoutSearch(() => this.loadTasks());
            this.selectedTasks = [];
        },
        type() {
            this.loadTasks();
            this.selectedTasks = [];
        }
    },
    created() {
        this.externalCompanyId = this.$route.params.id;
        this.loadTasks();
    },
    methods: {
        loadTasks() {
            this.toggleLoading();

            Tasks
                .index({
                    only_statuses: this.taskStatuses,
                    is_favorite: Number(this.isFavoriteType),
                    task_data: 'id,name,file_name,type,status,due_date',
                    with_project: 'id,name,internal_number',
                    with_internal_assignee: 'full_name',
                    with_external_assignee: 'full_name',
                    with_is_favorite: 1,
                    external_company_id: this.externalCompanyId,
                    search: this.search,
                })
                .then(response => this.tasks = response.data)
                .finally(() => this.toggleLoading())
        },
        goToView(item) {
            if (item.type === CONTRACT_TEMPLATE_TYPE)
                return this.$router.push({
                    name: 'TaskContracts',
                    params: {id: item.project.id, task_id: item.id},
                })

            this.$router.push({
                name: 'TaskRequest',
                params: {id: item.project.id, task_id: item.id},
            })
        },
        download() {
            const documentName = 'documents - ' + this.formatDate(new Date(), 'YYYY-MM-DD');

            Tasks
                .downloadDocuments({task_ids: this.selectedTasks})
                .then(response => this.downloadToast.open(`download.${response.token}`, documentName))
        },
        changeFav(item) {
            if (item.is_favorite)
                return this.$http.companies
                    .removeToQuickAccess({tasks: [item.id]})
                    .then(() => this.loadTasks())

            this.$http.companies
                .addToQuickAccess({tasks: [item.id]})
                .then(() => this.loadTasks(false))
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    }
}
</script>
