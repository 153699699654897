<template>
    <CCard class="mr-2 d-md-flex d-none" style="box-shadow: none">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem active> Documents</CBreadcrumbItem>
        </teleport>

        <CCardBody class="p-0 d-flex">
            <div class="contracts__filter pt-2 pb-2">
                <Filter v-model="type" :list="filterList" class="mb-4"/>
            </div>

            <div class="contracts__table pt-2 pb-2">
                <Table :type="type"/>
            </div>
        </CCardBody>
    </CCard>
</template>

<script>
import Filter from './components/Filter'
import Table from './components/Table'
import Tags from './components/Tags'
import Wrap from './components/Wrap'

export default {
    name: 'CompaniesList',
    components: {Filter, Table, Tags, Wrap},
    inject: ['mountedComponent'],
    data() {
        return {
            type: 'completed',
            filterList: [
                {label: 'Quick Access', value: 'favorite', icon: 'cilStar'},
                {label: 'Pending documents', value: 'pending', icon: 'cilDescription'},
                {label: 'Completed documents', value: 'completed', icon: 'cilStorage'},
            ],
        }
    },
}
</script>
<style lang="scss">
.contracts {
    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__filter {
        border-left: 5px solid #eaedef;
        border-right: 5px solid #eaedef;
        width: 210px;
    }

    &__table {
        border-left: 5px solid #eaedef;
        border-right: 5px solid #eaedef;
        display: flex;
        flex-direction: column;
        width: calc(100% - 210px);
    }
}
</style>
